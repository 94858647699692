import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { attachClosestEdge, extractClosestEdge, Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { IconButton, useTheme } from '@mui/material';
import { EventEmitterTypes } from 'types/event-emitter.type';
import Translation from 'components/data/Translation';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Menu from 'components/ui-components-v2/Menu';
import Divider from 'components/ui-components-v2/Divider';
import Icon from 'components/ui-components-v2/Icon';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { FRAME_WIDTH_PER_SECOND, GAP_WIDTH } from 'components/creatives-v2/constants';
import ComponentStore from 'components/data/ComponentStore';
import { EventEmitterHelpers } from 'helpers/event-emitter.helpers';
import { FrameActions } from '..';
import { ICreativeOverview } from '../types/creative-overview.type';
import { CreativeV2Template } from '../../creative-editor/types/creativeV2.type';
import '../styles/frame.scss';

interface ComponentStoreProps {
    activeFrame: ICreativeOverview['activeFrame'];
}

export interface FramesBarFrame {
    id: string;
    title: string;
    duration: number;
}

interface Props {
    editable: boolean;
    frames: FramesBarFrame[];
    frame: FramesBarFrame;
    hasBaseFrame: boolean;
    frameActions: FrameActions;
    creative: CreativeV2Template;
    onMoveFrame: (frame: FramesBarFrame, direction: number) => void;
}

const CreativeOverviewFrame: React.FC<Props> = ({ editable, frames, frame, hasBaseFrame, frameActions, creative, onMoveFrame }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isHovered, setIsHovered] = useState(false);
    const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
    const theme = useTheme();

    const { activeFrame } = useComponentStore<ComponentStoreProps>('CreativeOverview', {
        fields: {
            activeFrame: 'activeFrame'
        }
    });

    useEffect(() => {
        const el = ref.current;

        if (el) {
            return combine(
                draggable({
                    element: el,
                    canDrag: () => editable && frames.length > 1,
                    getInitialData: () => ({ frame, type: 'frame' })
                }),
                dropTargetForElements({
                    element: el,
                    getIsSticky: () => true, // Necessary because of the gaps between the drop targets
                    getData: ({ input, element }) => {
                        const data = { frame, type: 'frame' };
                        return attachClosestEdge(data, { input, element, allowedEdges: ['left', 'right'] });
                    },
                    onDragEnter: (args) => {
                        setClosestEdge(extractClosestEdge(args.self.data));
                    },
                    onDrag: (args) => {
                        setClosestEdge(extractClosestEdge(args.self.data));
                    },
                    onDragLeave: () => {
                        setClosestEdge(null);
                    },
                    onDrop: () => {
                        setClosestEdge(null);
                    }
                })
            );
        }
    }, [frames]);

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getWidth = () => {
        if (frame.duration) {
            return frame.duration * FRAME_WIDTH_PER_SECOND;
        }
        return 128;
    };

    const getStyle = () => {
        const width = getWidth();

        return {
            width: `${width}px`,
            maxWidth: `${width}px`,
            outlineColor: activeFrame === frame.id ? theme.palette.primary.main : 'lightgrey'
        };
    };

    const handleDeleteFrame = () => {
        ComponentStore.setMultiModels('CreativeOverview', [['playing', false]]);

        EventEmitterHelpers.sent(EventEmitterTypes.CEcurrentTime, 0);

        handleClose();

        if (frameActions.onDeleteFrame) frameActions.onDeleteFrame(frame);
    };

    const handleOnClick = () => {
        if (activeFrame === frame.id) return;
        ComponentStore.setModel('CreativeOverview', 'activeFrame', frame.id);
    };

    const getFramePreview = () => {
        const screenshot = creative?.data?.framePreviews?.[frame.id];
        return screenshot || '';
    };

    const moveFrame = (direction: number) => {
        onMoveFrame(frame, direction);
        handleClose();
    };

    // Disable the move left/right buttons if the frame is the first or last in the list
    const moveFrameDisabled = (direction: -1 | 1) => {
        const index = frames.findIndex((f) => f.id === frame.id);
        return direction === -1 ? index === 0 : index === frames.length - 1;
    };

    return (
        <div
            className={classNames('creative-overview-frame', {
                'creative-overview-frame--has-base-frame': hasBaseFrame
            })}
            ref={ref}
            data-type="creative-frame" // used in the frames-bar component
            style={getStyle()}
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => {
                // Prevent the mouseleave event from firing when the popover is opened, otherwise the menu shoots to the top left
                if (anchorEl) return;
                setIsHovered(false);
            }}
            onClick={() => handleOnClick()}>
            {getFramePreview() ? (
                <img style={{ width: '100%', height: '100%', pointerEvents: 'none', objectFit: 'contain' }} src={getFramePreview()} />
            ) : (
                frame.title
            )}
            {isHovered && editable && (
                <div className="creative-overview-frame__options">
                    <IconButton onClick={(e) => handleOpenPopover(e)} size="small">
                        <Icon style={{ fontSize: 18 }}>more_horiz</Icon>
                    </IconButton>
                </div>
            )}
            {closestEdge && <DropIndicator edge={closestEdge} gap={`${GAP_WIDTH}px`} />}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                    disabled={moveFrameDisabled(-1)}
                    onClick={(e) => {
                        e.stopPropagation();
                        moveFrame(-1);
                    }}>
                    <ListItemIcon>
                        <Icon>arrow_back</Icon>
                    </ListItemIcon>
                    <ListItemText primary={Translation.get('actions.moveLeft', 'common')} />
                </MenuItem>

                <MenuItem
                    disabled={moveFrameDisabled(1)}
                    onClick={(e) => {
                        e.stopPropagation();
                        moveFrame(1);
                    }}>
                    <ListItemIcon>
                        <Icon>arrow_forward</Icon>
                    </ListItemIcon>
                    <ListItemText primary={Translation.get('actions.moveRight', 'common')} />
                </MenuItem>

                <Divider />

                <MenuItem
                    disabled={frames.length === 1}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteFrame();
                    }}>
                    <ListItemIcon>
                        <Icon>delete</Icon>
                    </ListItemIcon>
                    <ListItemText primary={Translation.get('actions.delete', 'common')} />
                </MenuItem>
            </Menu>
        </div>
    );
};

export default CreativeOverviewFrame;
