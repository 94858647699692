import { Edge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef } from 'react';
import { EventEmitterTypes } from 'types/event-emitter.type';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import { getCreativeInstance } from 'components/creatives-v2/helpers/creatives-factory';
import ComponentStore from 'components/data/ComponentStore';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Icon from 'components/ui-components-v2/Icon';
import cloneDeep from 'helpers/cloneDeep';
import { EventEmitterHelpers } from 'helpers/event-emitter.helpers';
import { TDTemplateAsset } from 'components/template-management/types/template-management.type';
import { SceneHelpers } from 'helpers/scene.helpers';
import { FrameActions } from '..';
import { CreativeV2Template } from '../../creative-editor/types/creativeV2.type';
import CreativeOverviewHelpers from '../helpers/creative-overview.helpers';
import { ICreativeOverview } from '../types/creative-overview.type';
import Frame, { FramesBarFrame } from './frame';
import CreativeOverviewProgressBar from './progress-bar';

import '../styles/frames-bar.scss';

interface ComponentStoreProps {
    duration: ICreativeOverview['duration'];
    activeFrame: ICreativeOverview['activeFrame'];
    playing: ICreativeOverview['playing'];
    disabledFeatures: ICreativeOverview['disabledFeatures'];
}

interface Props {
    creative: CreativeV2Template;
    editable: boolean;
    frameActions: FrameActions;
}

const CreativeOverviewFramesBar: React.FC<Props> = ({ creative, editable, frameActions }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const framesRef = useRef<FramesBarFrame[]>([]);
    const [dragging, setDragging] = React.useState(false);
    const {
        duration,
        activeFrame,
        playing,
        disabledFeatures = []
    } = useComponentStore<ComponentStoreProps>('CreativeOverview', {
        fields: {
            duration: 'duration',
            activeFrame: 'activeFrame',
            playing: 'playing',
            disabledFeatures: 'disabledFeatures'
        }
    });

    useEffect(() => {
        const el = ref.current;

        if (el) {
            return monitorForElements({
                onDragStart: () => {
                    setDragging(true);
                    SceneHelpers.pauseScene();
                    ComponentStore.setModel('CreativeOverview', 'playing', false);
                    EventEmitterHelpers.sent(EventEmitterTypes.CEiframe, { action: { type: 'pause' } });
                },
                onDrop: (args) => {
                    const { location, source } = args;
                    const target = location.current.dropTargets[0];
                    setDragging(false);

                    if (!target) return;

                    if (source.data.type === 'frame') {
                        const sourceFrame = source.data.frame as FramesBarFrame;
                        const targetFrame = target.data.frame as FramesBarFrame;
                        const closestEdgeOfTarget = extractClosestEdge(target.data);
                        reorderFrames(framesRef.current, sourceFrame, targetFrame, closestEdgeOfTarget);
                    }
                }
            });
        }
    }, []);

    const creativeTemplate = useMemo(() => TemplateManager.getTemplateByIdentifier(creative.data.templateIdentifier), [creative.data?.templateIdentifier]);

    const isCreativeMultiFrame = useMemo(() => getCreativeInstance(creative).isMultiFrame(), [creative]);

    const frames: FramesBarFrame[] = useMemo(() => {
        const templateInput = cloneDeep(creative.data?.templateInput);

        if (!templateInput || !Object.keys(templateInput).length) {
            const duration = CreativeOverviewHelpers.getFrameTypeDuration(creative);
            return [
                {
                    id: 'frame1',
                    title: 'Frame 1',
                    duration: duration
                }
            ];
        }

        const frames = Object.keys(templateInput.frames)
            .filter((frame) => frame !== 'base')
            .map((frame, index) => {
                const frameType = templateInput.frames[frame].type;

                return {
                    id: `frame${index + 1}`,
                    title: `Frame ${index + 1}`,
                    duration: CreativeOverviewHelpers.getFrameTypeDuration(creative, frameType)
                };
            });

        return frames;
    }, [creative.data?.templateInput]);

    // We use the framesRef, because the reorderFrames function is called in the initial useEffect hook and we need the updated frames
    useEffect(() => {
        framesRef.current = frames;
    }, [frames]);

    const reorderFrames = (frames: FramesBarFrame[], sourceFrame: FramesBarFrame, targetFrame: FramesBarFrame, closestEdgeOfTarget: Edge | null) => {
        if (!frames || frames.length < 2) return;

        const startIndex = frames.findIndex((frame) => frame.id === sourceFrame.id);
        const indexOfTarget = frames.findIndex((frame) => frame.id === targetFrame.id);

        const updatedFrames: FramesBarFrame[] = reorderWithEdge({
            list: frames,
            startIndex,
            closestEdgeOfTarget,
            indexOfTarget,
            axis: 'horizontal'
        });

        frameActions.onChangeFrameOrder && frameActions.onChangeFrameOrder(updatedFrames);
    };

    useEffect(() => {
        const totalDuration = frames.reduce((acc, frame) => acc + frame.duration, 0);

        if (totalDuration > 0) {
            const modelsToUpdate: (string | number)[][] = [];

            if (duration !== totalDuration) {
                modelsToUpdate.push(['duration', totalDuration]);
            }

            if (!activeFrame) {
                modelsToUpdate.push(['activeFrame', frames[0].id]);
            }

            if (modelsToUpdate.length > 0) {
                ComponentStore.setMultiModels('CreativeOverview', modelsToUpdate);
            }
        }
    }, [frames, activeFrame]);

    useEffect(() => {
        if (playing) return;

        // If the scene is paused, we need to update the active frame based on the scene time, if necessary
        const sceneTime = SceneHelpers.getSceneTime(duration);
        const currentActiveFrame = findActiveFrame(frames, sceneTime);

        if (!currentActiveFrame) return;

        if (currentActiveFrame.id !== activeFrame) {
            ComponentStore.setModel('CreativeOverview', 'activeFrame', currentActiveFrame.id);
        }
    }, [playing]);

    const findActiveFrame = (frames: FramesBarFrame[], currentTime: number): FramesBarFrame => {
        if (isNaN(currentTime)) return frames[0];

        let accumulatedTime = 0;
        for (const frame of frames) {
            accumulatedTime += frame.duration;
            if (currentTime < accumulatedTime) {
                return frame;
            }
        }
        // If currentTime exceeds the total duration of all frames, return the last frame
        return frames[frames.length - 1];
    };

    const handleMouseUp = (event) => {
        if (!duration) return;

        // This prevents setting the progressbar if you click on any actions in the frame
        if (event?.target?.dataset?.type === 'creative-frame') {
            const editorProgress = getProgressPercentage(event);
            if (!creativeTemplate) return;

            let iframeProgress = CreativeOverviewHelpers.getProgress(editorProgress, creative, creativeTemplate, false);

            if (iframeProgress < 1) iframeProgress = 0;

            CreativeOverviewHelpers.seek(iframeProgress, duration);
        }
    };

    const handleMouseMove = (event) => {
        EventEmitterHelpers.sent(EventEmitterTypes.CEhoverPosition, getProgressPercentage(event));
    };

    const getProgressPercentage = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const left = event.pageX - rect.left;
        const divWidth = event.currentTarget.offsetWidth;
        const calculatedPercentage = (left / divWidth) * 100;
        return calculatedPercentage;
    };

    const handleMouseLeave = () => {
        EventEmitterHelpers.sent(EventEmitterTypes.CEhoverPosition, null);
    };

    const handleClickBaseFrame = () => {
        ComponentStore.setModel('CreativeOverview', 'activeFrame', 'base');
    };

    const onAddFrame = () => {
        frameActions.onAddFrame && frameActions.onAddFrame();

        const oldTotalDuration = frames.reduce((acc, frame) => acc + frame.duration, 0);
        const extraDuration = CreativeOverviewHelpers.getFrameTypeDuration(creative);
        const newDuration = oldTotalDuration + extraDuration;

        // This is the new progress in percentages if you start at the beginning of the new frame
        const newProgress = (oldTotalDuration / newDuration) * 100 + 0.1;

        // Set the new frame as active in the creative overview // TODO is there a better way?
        ComponentStore.setModel('CreativeOverview', 'activeFrame', `frame${frames.length + 1}`);

        // Seek to this new point after the frame is added
        // It needs a setTimeout because scene has to rerender
        setTimeout(() => {
            CreativeOverviewHelpers.seek(newProgress, newDuration);
        }, 500);
    };

    // Move a frame left or right and return the updated frames array
    const handleMoveFrame = (frameToMove: FramesBarFrame, direction: number): FramesBarFrame[] => {
        const index = frames.findIndex((frame) => frame.id === frameToMove.id);

        if (index === -1) {
            // If the frame is not found, return the original array
            return frames;
        }

        const newIndex = index + direction;
        if (newIndex < 0 || newIndex >= frames.length) {
            // If moving the frame would go out of bounds, return the original array
            return frames;
        }

        // Swap frames in the array
        const updatedFrames = [...frames];
        [updatedFrames[index], updatedFrames[newIndex]] = [updatedFrames[newIndex], updatedFrames[index]];

        frameActions.onChangeFrameOrder && frameActions.onChangeFrameOrder(updatedFrames);

        return updatedFrames;
    };

    // Check if one of the frames is of type 'base'
    const hasBaseFrame = useMemo(() => {
        const template = TemplateManager.getTemplateByIdentifier(creative.data.templateIdentifier) as TDTemplateAsset;
        return !!template?.data.interfaceSetup?.some((item) => item.path === 'base');
    }, [creative.data.templateIdentifier]);

    return (
        <div className="creative-overview-frames-bar">
            <div className="creative-overview-frames-bar__wrap">
                <div
                    className={classNames('creative-overview-frames-bar__frames', {
                        'creative-overview-frames-bar__frames--dragging': dragging
                    })}
                    ref={ref}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}>
                    <CreativeOverviewProgressBar creative={creative} />
                    <div className="creative-overview-frames-bar__frames__items">
                        {frames.map((frame) => (
                            <Frame
                                key={frame.id}
                                frames={frames}
                                frame={frame}
                                hasBaseFrame={hasBaseFrame}
                                editable={editable}
                                frameActions={frameActions}
                                creative={creative}
                                onMoveFrame={handleMoveFrame}
                            />
                        ))}
                    </div>
                </div>
                {hasBaseFrame && (
                    <div
                        onClick={() => handleClickBaseFrame()}
                        className={classNames('creative-overview-frames-bar__frames__base-frame', {
                            'creative-overview-frames-bar__frames__base-frame--active': activeFrame === 'base'
                        })}>
                        base
                    </div>
                )}
            </div>

            {isCreativeMultiFrame && editable && !disabledFeatures.includes('addFrame') && (
                <div className="creative-overview-frames-bar__add-frame" onClick={() => onAddFrame()}>
                    <Icon fontSize="small">add</Icon>
                </div>
            )}
        </div>
    );
};

export default CreativeOverviewFramesBar;
