import React, { useEffect } from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { IframeData, IframeOverwrites } from 'components/creatives-v2/creative-types/template-creative.class';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import CreativeOverviewCanvasFree from './canvas-free';
import { ICreativeOverview } from '../types/creative-overview.type';
import GridWrapper from '../../grid-wrapper';
import '../styles/canvas.scss';

interface ComponentStoreProps {
    view: ICreativeOverview['view'];
    creatives: ICreativeOverview['creatives'];
}

interface Props {
    iframeOverwrites?: IframeOverwrites<IframeData>;
    headerComponent?: React.ReactElement;
    editable?: boolean;
}

const CreativeOverviewCanvas = ({ iframeOverwrites, headerComponent, editable }: Props) => {
    const { view, creatives } = useComponentStore<ComponentStoreProps>('CreativeOverview', {
        fields: {
            view: 'view',
            creatives: 'creatives'
        }
    });

    useEffect(() => {
        if (view === 'canvasFixed') {
            // Reset the zoom when switching to fixed views
            ComponentStoreHelpers.setModel('CreativeOverview', 'canvasZoom', 1);
        }
    }, [view]);

    if (!creatives) return null;

    return (
        <div className="creative-overview-canvas" style={{ overflowY: view === 'canvasFixed' ? 'scroll' : 'auto' }}>
            {view === 'canvasFixed' && (
                <GridWrapper creatives={creatives} iframeOverwrites={iframeOverwrites} headerComponent={headerComponent} editable={editable} view={view} />
            )}
            {(view === 'canvasFree' || view === 'single') && (
                <CreativeOverviewCanvasFree iframeOverwrites={iframeOverwrites} headerComponent={headerComponent} editable={editable} />
            )}
        </div>
    );
};

export default CreativeOverviewCanvas;
