import React, { useEffect, useRef, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { EventEmitterTypes } from 'types/event-emitter.type';
import { useEventEmitterListener } from 'hooks/useEventEmitterListener';
import NativeScene from '@bycape/scenejs';
import { SceneHelpers } from 'helpers/scene.helpers';
import { EventEmitterHelpers } from 'helpers/event-emitter.helpers';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStore from 'components/data/ComponentStore';
import ProgressBar from 'components/ui-components/ProgressBar';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import { Scene } from 'components/template-designer/scene/scene';
import { CreativeV2Template } from '../../creative-editor/types/creativeV2.type';
import { ICreativeOverview } from '../types/creative-overview.type';
import CreativeOverviewHelpers from '../helpers/creative-overview.helpers';
import '../styles/progress-bar.scss';

interface ComponentStoreProps {
    playing: ICreativeOverview['playing'];
    duration: ICreativeOverview['duration'];
    sceneKey: ICreativeOverview['sceneKey'];
}

interface Props {
    creative: CreativeV2Template;
    slider?: boolean;
}

const CreativeOverviewProgressBar: React.FC<Props> = ({ creative, slider = false }) => {
    const sceneRef = useRef<NativeScene>(null);
    const durationRef = useRef<number>(0);

    const { playing, duration, sceneKey } = useComponentStore<ComponentStoreProps>('CreativeOverview', {
        fields: {
            playing: 'playing',
            duration: 'duration',
            sceneKey: 'sceneKey'
        }
    });

    const creativeTemplate = useMemo(() => TemplateManager.getTemplateByIdentifier(creative.data.templateIdentifier), [creative?.data?.templateIdentifier]);

    const currentTime = useEventEmitterListener(EventEmitterTypes.CEcurrentTime) ?? 0;
    const hoverPosition = useEventEmitterListener(EventEmitterTypes.CEhoverPosition) ?? null;

    // Make sure the scene is updated when the duration changes
    useEffect(() => {
        // If the duration changes (e.g. adding or removing frames), Scene has to rerender. We do this by changing the sceneKey
        if (durationRef.current !== duration) {
            durationRef.current = duration;
            ComponentStore.setModel('CreativeOverview', 'sceneKey', uuidv4());
        }

        sceneRef.current && SceneHelpers.setScene(sceneRef.current);
    }, [duration, sceneKey]);

    // For templates without frames, we need to set the duration in here (with frames, it happens in the frames-bar)
    useEffect(() => {
        if (!slider) return;

        const duration = CreativeOverviewHelpers.getFrameTypeDuration(creative);
        if (duration > 0) {
            // TODO make it work without the timeout
            setTimeout(() => {
                ComponentStore.setModel('CreativeOverview', 'duration', duration);
            });
        }
    }, []);

    /* Make sure to update the iframes according to the scene progress */
    const updateIframeProgress = (currentTime = 0, duration = 1, playing = false) => {
        if (!playing) return;

        const seekTime = currentTime / duration;
        if (seekTime === 0 || seekTime > 0.95) return;

        EventEmitterHelpers.sent(EventEmitterTypes.CEiframe, { action: { type: 'seek', seekTime, playAfterSeek: true } });
    };

    // Function to be throttled to execute once per 0.3 seconds, to keep all frames up to date
    // Can cause lagg, so we only want to turn this back on if it's necessary
    // const throttledUpdate = useCallback(throttle(updateIframeProgress, 300), []);

    /* Update the currentTime when the animation is playing */
    const handleTimeUpdate = (e) => {
        const playState = e.currentTarget.state.playState;

        // throttledUpdate(e.currentTime, duration, playing); // TODO Determine if this is necessary, because it causes some lagg in animations

        if (playState === 'running') {
            EventEmitterHelpers.sent(EventEmitterTypes.CEcurrentTime, parseFloat(e.currentTime));
        }
    };

    const getProgress = () => {
        if (!duration) return;

        // Progress from the scene object
        const progress = ((currentTime || 0) / duration) * 100;

        if (!creativeTemplate) return `${progress}%`;

        // The progress in the editor is a bit different, because of the gaps between the frames. This makes sure the gaps are skipped
        const editorProgress = CreativeOverviewHelpers.getProgress(progress, creative, creativeTemplate, true);

        return `${editorProgress}%`;
    };

    // If the scene ends, update the state
    const handleOnEnd = () => {
        ComponentStore.setModel('CreativeOverview', 'playing', false);
        EventEmitterHelpers.sent(EventEmitterTypes.CEcurrentTime, Number(duration));
    };

    const getHoverSec = () => {
        if (!hoverPosition || !duration) return '';
        return `${((hoverPosition * duration) / 100).toFixed(1)}s`;
    };

    const handleOnChange = (_event: Event, progress: number | Array<number>) => {
        if (Array.isArray(progress)) return;
        CreativeOverviewHelpers.seek(progress, duration);
    };

    return (
        <>
            {slider ? (
                <div className="creative-overview-progress-bar__slider">
                    <ProgressBar
                        value={(currentTime / duration) * 100}
                        onChange={handleOnChange}
                        playAnimation={playing}
                        size={'small'}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value: number) => {
                            return `${((value * duration) / 100).toFixed(2)}s`;
                        }}
                    />
                </div>
            ) : (
                <div className="creative-overview-progress-bar">
                    {!!hoverPosition && (
                        <div className="creative-overview-progress-bar__vert" style={{ left: `${hoverPosition}%` }}>
                            <div className="creative-overview-progress-bar__vert__sec">{getHoverSec()}</div>
                        </div>
                    )}
                    {duration && (
                        <div className="creative-overview-progress-bar__vert-progress" style={{ left: getProgress() }}>
                            <div className="creative-overview-progress-bar__vert-progress__dot"></div>
                        </div>
                    )}
                </div>
            )}
            <>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Scene
                    key={sceneKey}
                    time={currentTime}
                    ref={sceneRef}
                    duration={duration}
                    fillMode="forwards"
                    iterationCount={1}
                    onTimeUpdate={handleTimeUpdate}
                    onEnded={handleOnEnd}
                    autoplay={false}></Scene>
            </>
        </>
    );
};

export default CreativeOverviewProgressBar;
